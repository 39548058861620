import { ChangeDetectionStrategy, Component, DestroyRef, inject, isDevMode, OnInit } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { RouterOutlet } from '@angular/router';
import { fromEvent } from 'rxjs';

import { IntercomService } from '@ideals/partial-views/intercom';
import { PageMessagesModule } from '@ideals/partial-views/page-messages';
import { BrowserService } from '@ideals/services/browser';
import { FeatureToggleService } from '@ideals/services/feature-toggle';
import { GoogleAnalyticsService } from '@ideals/services/google-analytics';
import { HeapService } from '@ideals/services/heap';
import { WINDOW } from '@ideals/types';
import { SpinnerModule } from '@ideals/ui-components/spinner';
import { isInternalUrl, sendMessageToDevice } from '@ideals/utils';

import { environment } from '../environments/environment';

import { DnsPrefetchService } from './core/services/dns-prefetch';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    PageMessagesModule,
    RouterOutlet,
    SpinnerModule,
  ],
  selector: 'auth-root',
  standalone: true,
  templateUrl: './root.component.html',
})
export class RootComponent implements OnInit {
  readonly #browserService = inject(BrowserService);
  readonly #destroyRef = inject(DestroyRef);
  readonly #dnsPrefetchService = inject(DnsPrefetchService);
  readonly #featureToggleService = inject(FeatureToggleService);
  readonly #googleAnalyticsService = inject(GoogleAnalyticsService);
  readonly #heapService = inject(HeapService);
  readonly #intercomService = inject(IntercomService);
  readonly #window = inject(WINDOW);

  ngOnInit(): void {
    this.#window.dispatchEvent(new Event('ideals:appInited'));
    this.#intercomService.loadIntercom();
    this.#dnsPrefetchService.createLinks();

    if (this.#browserService.isStandalone) {
      this.#subscribeToGlobalLinkClick();
    }

    if (this.#featureToggleService.isEnabled('fvdr-orion-heap-enabled') && !isDevMode()) {
      this.#heapService.runHeap(environment.heapId);
    }

    if (this.#featureToggleService.isEnabled('fvdr-altair-ga-enabled') && !isDevMode()) {
      this.#googleAnalyticsService.initialize(environment.googleTagId);
    }
  }

  #subscribeToGlobalLinkClick(): void {
    fromEvent(document.body, 'click')
      .pipe(takeUntilDestroyed(this.#destroyRef))
      .subscribe((event) => {
        const target = event.target as HTMLElement;
        const link: HTMLLinkElement | null = target.tagName === 'A'
          ? target as HTMLLinkElement
          : target.closest<HTMLLinkElement>('a');

        if (link?.href && !isInternalUrl(link.href)) {
          event.preventDefault();
          sendMessageToDevice('openExternalUrl', link.href);
        }
      });
  }
}
