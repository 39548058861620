import { DOCUMENT } from '@angular/common';
import { inject, Injectable, RendererFactory2 } from '@angular/core';

declare global {
  interface Window {
    dataLayer: unknown[];
  }
}

@Injectable({ providedIn: 'root' })
export class GoogleAnalyticsService {
  readonly #document = inject(DOCUMENT);
  readonly #renderer2 = inject(RendererFactory2).createRenderer(null, null);

  initialize(tagId?: string): void {
    if (!tagId) {
      return;
    }

    this.#createGAScript(tagId);
  }

  #createGAScript(tagId: string): void {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      'gtm.start': Date.now(),
      event: 'gtm.js',
    });

    const script = this.#renderer2.createElement('script') as HTMLScriptElement;
    const scriptSrc = `https://www.googletagmanager.com/gtm.js?id=${tagId}`;

    this.#renderer2.setAttribute(script, 'async', '');
    this.#renderer2.setAttribute(script, 'src', scriptSrc);
    this.#renderer2.appendChild(this.#document.head, script);
  }
}
