<ng-container>
  <ideals-input-control-wrapper #wrapper=wrapper
                                class="ideals-input-control-wrapper"
                                [id]="id"
                                [label]="label"
                                [isRequired]="isRequired"
                                [isOptional]="isOptional"
                                [labelPosition]="labelPosition"
                                [labelCustomClass]="labelCustomClass"
                                [prefixClass]="prefixClass"
                                [suffixClass]="suffixClass"
                                [suffixTemplate]="suffixTemplate"
                                [labelSuffixTemplate]="labelSuffixTemplate"
                                [errorList]="errorList"
                                [errorsToShow]="errorsToShow"
                                [passwordClassName]="passwordClassName"
                                [canShowError]="canShowError"
                                [canShowSuccess]="canShowSuccess"
                                [errorTransitionEnabled]="errorTransitionEnabled"
                                [processing]="processing"
                                [inputCustomClass]="inputCustomClass">

    <ng-container *ngIf="isInput; then input; else textarea"></ng-container>

    <ng-template #input>
      <input #idealsInput
             class="ideals-input-element"
             idealsDigitOnly
             [digitOnlyDisabled]="!digitOnly"
             [decimal]="decimal"
             [id]="wrapper.id"
             [type]="type"
             [maxlength]="maxLength"
             [placeholder]="placeholder | translate"
             [disabled]="disabled"
             [readonly]="readonly"
             [mask]="mask"
             [patterns]="maskPatterns"
             [showMaskTyped]="showMaskTyped"
             [placeHolderCharacter]="placeHolderCharacter"
             [ngModel]="value"
             [attr.name]="name"
             [attr.spellcheck]="spellcheck"
             [inputTransformFn]="inputTransformFn"
             (keyup.enter)="onKeyUpEnter()"
             (focus)="inputFocus()"
             (blur)="inputBlur()"
             (ngModelChange)="modelChange($event)"/>
    </ng-template>
    <ng-template #textarea>
      <textarea class="ideals-input-textarea"
                idealsAutosize
                [id]="wrapper.id"
                [minRows]="minRows"
                [placeholder]="placeholder | translate"
                [disabled]="disabled"
                [ngModel]="value"
                (ngModelChange)="modelChange($event)"
                data-ideals="textarea">
      </textarea>
    </ng-template>

  </ideals-input-control-wrapper>
</ng-container>
